import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/apps/store/src/components/CategoryList/Item.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/apps/store/src/components/CategoryList/track.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/opt/build/repo/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/i10n/src/Price.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/i10n/src/RegionSelector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BunnyLoader"] */ "/opt/build/repo/packages/utils/src/bunnyLoader.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/cleanStaleCartId.ts");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/clearRandomIdsFromBundles.ts");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/useDetectDevice.ts");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/useDocumentReady.ts");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/useDocumentVisible.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/useLocalStorage.ts");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/useOutsideClickCapture.ts");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/useScroll.ts");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/useWindowWidth.tsx");
